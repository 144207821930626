.common-users {
  padding-top: 7px;
  .common-users__page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    h1 {
      display: block !important;
    }
  }
  p {
    font-weight: 500;
    span {
      font-weight: 700;
    }
  }
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ul {
    display: flex;
    margin-bottom: 10px;
  }
  li {
    margin-right: 10px;
    background-color: #2e1d30;
    border-radius: 3px;
    border: 1px solid white;
    padding: 5px 10px;
    cursor: pointer;
    &.active {
      color: #2ae5dd;
    }
  }
  .common-users__page-content {
    overflow: auto;
    max-height: calc(100vh - 350px);
    min-height: 68vh;
    transition: all 0.5s;
    //&.minimize {
    //  animation: slideInFromTop 1.5s ease-in-out;
    //}
    table {
      animation: openAnimation 0.5s ease-in-out;
      thead th {
        position: sticky;
        top: 0;
      }
    }
    @keyframes openAnimation {
      from {
        transform: translateY(-100%) scaleY(0);
      }
      to {
        transform: translateY(0) scaleY(1);
      }
    }
    th {
      background-color: #2e1d30;
    }
    .table > thead > tr > th,
    .table > tbody > tr > td {
      padding-left: 20px;
    }
    th:nth-child(3),
    td:nth-child(3),
    td:nth-child(4) {
      text-align: start;
    }
    td {
      width: 50%;
    }
  }
}
