.header-plsummary{
    border-bottom: 1px solid #fff;
}

.subheading-pl{
    padding-top: 10px;
}

tr{
    cursor: pointer;
}
.text-red{
    color: red;
}

.text-green{
    color: #3dd247;
}