@import "../ModalCustom/index.scss";

.children-table__container {
  .custom-modal__container {
    max-width: 760px;
    width: 90vw;
    h3 {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
    h4 {
      font-size: 1rem;
      font-weight: bold;
    }
    th,
    td {
      padding: 2px;
    }
    th {
      font-weight: bold;
      color: white;
    }
    input {
      height: 32px;
      font-weight: normal;
      color: #444444;
      & + label {
        top: -5px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }

    .container {
      @extend .custom-modal__container;
      margin-bottom: 1.43rem;
      padding: 1.43rem 1.43rem;
    }

    &.modal-child-info {
      background: none;
      overflow-x: visible;
      overflow-y: auto;
      max-width: 800px;
      padding: 100px 20px;
      max-height: 100vh;
      margin: 0;

      // input {
      //     max-width: 150px;
      // }

      .custom-modal__close-icon {
        top: 80px;
        right: 0;
      }

      .container {
        .check {
          padding: 8px;
          label {
            position: relative;
            top: 0;
            left: 8px;
            font-weight: normal;
          }
        }
        table {
          td,
          th {
            vertical-align: middle;
            white-space: nowrap;
            padding: 8px;
          }
        }
        &.information > div:not(:last-child) {
          margin-bottom: 1.8rem;
        }
        &.credit .container__body {
          display: grid;
          grid-template-columns: minmax(250px, max-content) minmax(150px, min-content);

          > div:first-child {
            padding-right: 10px;
          }

          .credit__input:first-child {
            padding: 0 !important;
          }

          .credit__input {
            padding: 10px 0px;
            & > div {
              display: inline-block;
              & > span {
                display: block;
              }
            }

            .credit__params {
              position: relative;
              top: 15px;
              left: 2px;
            }
          }
        }
        &.notes textarea {
          min-height: 100px;
          width: 100%;
          color: black;
        }

        input:disabled {
          opacity: 0.6;
        }
      }
      .modal-child-info__controls {
        position: relative;
        bottom: 0;
        right: 0px;
        display: flex;
        justify-content: flex-end;
      }
    }

    &.modal-descendant-info {
      background: none;
      .container {
        &.information {
          th,
          td {
            text-align: left;
          }
        }

        &.bet-settings,
        &.position-taking {
          th,
          td {
            text-align: center;
          }
          td:first-child,
          th:first-child {
            text-align: left;
          }
        }
        * {
          -moz-user-select: -moz-none;
          -o-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          user-select: none;
        }
      }
      .close-button {
        position: absolute;
        bottom: -35px;
        right: 0;
        border: 2px dotted green;
      }
    }
    .close-button {
      color: #18c432;
    }
  }
}
.information__password-input-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  .custom-text-input_white {
    width: 57%;
    height: 42px;
    pointer-events: none;
    input {
      opacity: 1 !important;
    }
  }
  .information__copy-button {
    width: 40%;
    height: 32px;
    background: #3C8132;
    box-shadow: inset -2px -2px 0px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
  .information__copy-button:hover {
    transform: translate(-1px, -1px);
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
    background: #408b36;
  }
  .information__copy-button:active {
    transform: translate(1px, 1px);
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
  }
  .information__password-generate {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 52%;
    top: 0;
    transform: translateY(62%);
    z-index: 1;
    cursor: pointer;
    transition: all 0.5s;
  }
  .information__password-generate:after {
    content: "";
    background-color: #3C8132;
    mask-image: url("../../static/images/reload-pass.svg");
    mask-repeat: no-repeat;
    mask-size: 15px;
    mask-position: center;
    width: 32px;
    height: 32px;
    position: absolute;
  }
  .information__password-generate.active:after {
    animation: circle 0.4s;
  }
  @keyframes circle{
    0%{
      transform: rotate(0deg);
      //mask-size: 16px;
    }
    60%{
      transform: rotate(360deg);
      //mask-size: 8px;
    }
    100%{
      transform: rotate(360deg);
      //mask-size: 16px;
    }
  }
}
.modal-descendant-info {
  .container {
    Table {
      color: #FFFFFF;
    }
  }
}
.modal-child-info__containers {
  Table {
    color: #FFFFFF;
  }
}

.password-sect{
  width: 50%;
}
